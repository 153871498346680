import '../scss/main.scss'
import { run } from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

// https://cookieconsent.orestbida.com/essential/getting-started.html

// Enable dark mode
document.documentElement.classList.add('cc--darkmode')

run({
  onFirstConsent: ({cookie}) => {
    // do something
  },

  onConsent: ({cookie}) => {
    // consent was given
  },

  onChange: ({changedCategories, changedServices}) => {
    // user changed his/her preferences
  },

  cookie: {
    name: 'cc_cookie',
    domain: window.location.hostname,
    path: '/',
    expiresAfterDays: 182,
    sameSite: 'Lax'
  },

  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false
    },
    "preferencesModal": {
      "layout": "box",
      "position": "right",
      "equalWeightButtons": true,
      "flipButtons": false
    }
  },

  categories: {
    necessary: {
      enabled: true,
      readOnly: true
    }
  },

  language: {
    default: 'de',
    translations: {
      de: require('./cookieconsent-de.json')
    }
  },

  disablePageInteraction: false
})

/*
window.addEventListener('cc:onFirstConsent', (e) => {
  console.log(e)
})
*/

